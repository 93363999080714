<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <!-- <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <router-link :to="{ name: 'admin.dashboard' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ customer.unverified }}</h2>
                  <p class="fontsize-sm m-0 text-info">
                    Unverified
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-info">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <router-link :to="{ name: 'admin.dashboard' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ customer.verifying }}</h2>
                  <p class="fontsize-sm m-0 text-warning">
                    Verifiying
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-warning">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <router-link :to="{ name: 'admin.dashboard' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ customer.verified }}</h2>
                  <p class="fontsize-sm m-0 text-success">
                    Verified
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <router-link :to="{ name: 'admin.dashboard' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ customer.invalidated }}</h2>
                  <p class="fontsize-sm m-0 text-danger">
                    Invalidated
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-danger">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AdminDashboardService } from '@/services'

export default {
  name: 'AdminDashboard',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      customer: {
        unverified: 0,
        verifying: 0,
        verified: 0,
        invalidated: 0
      }
    }
  },

  mounted () {
    core.index()
    // this.getStatistics()
  },

  methods: {
    async getStatistics () {
      AdminDashboardService.getStatistics().then(({ data }) => {
        this.customer = data
      })
    }
  }
}
</script>
